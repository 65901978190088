import React from "react";
import { Link } from "react-router-dom";
import "./Change.css";
function Change(props) {
  const { item } = props;
  const seperator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="divid">
      <Link target="_blank" to={'/admin/listings/'+item._id}>
        <img src={item.gallery[0]} width="100%" height="100%" />
      </Link>
      <div className="blured">
        <i
          className="menu-icon bx bx-check-double bx-sm"
          style={{ fontSize: 22 }}
        ></i>
        {seperator(item.views.length)}
      </div>
    </div>
  );
}

export default Change;
