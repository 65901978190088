import React from 'react';
import Footer from "../components/Footer";
import Nav from "../components/Nav";

function Terms(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
    return (
        <div style={{backgroundColor:"white",color:"black"}}>
        <div
          style={{
            background:
              "linear-gradient(rgba(30, 149, 155, 0.7), rgba(243, 168, 35, 0.7)), url(https://source.unsplash.com/random)",
            width: "100%",
            minHeight: "50vh"
          }}
        >
          <Nav />
          <div
            style={{
              width: "100%",
              textAlign: "center",
              height: "100%",
              position: "absolute",
              top: "30vh",
              fontSize: "2.5em",
              color: "#36324a",
              fontWeight: "bold",
            }}
          >
            Terms of Serivce
          </div>
        </div>
        <div style={{paddingTop: 16,
      borderTop: '1px solid #eee',backgroundColor:"#fff"}}>
          <div style={{    position: 'relative',
      width: '90%',
      maxWidth: '1024px',
      margin: '0 auto',backgroundColor:"#fff"}}>
             <div id="en">
           
            
              <h4 style={{color:"black"}}>
                  Welcome to SwapperLand!
              </h4>
              <p style={{marginTop:50}}>
              These Terms of Use (or "Terms") govern your use of SwapperLand, except where we expressly state that separate terms (and not these) apply, and provide information about the SwapperLand Service (the "Service"), outlined below. When you create an SwapperLand account or use SwapperLand, you agree to these Terms.
              </p>
              <p>
              The SwapperLand Service is one of the Dortana Products, with which you are provided by Dortana Kft. These Terms of Use therefore constitute an agreement between you and Dortana Kft.
              </p>
              <br/>
              <h4 style={{color:"black"}}>Your commitments</h4>
              <p>In return for our commitment to provide the Service, we require you to make the below commitments to us.</p>
              <p>
              <span style={{fontWeight:"bold"}}>Who can use SwapperLand.</span> We want our Service to be as open and inclusive as possible, but we also want it to be safe, secure and in accordance with the law. So, we need you to commit to a few restrictions in order to be part of the SwapperLand community.
              </p>
              <ul>
                <li>You must be at least 4 years old.</li>
                <li>You must not be prohibited from receiving any aspect of our Service under applicable laws or engaging in payments-related Services if you are on an applicable denied party listing.</li>
                <li>We must not have previously disabled your account for violation of law or any of our policies.</li>
                <li>You must not be a convicted sex offender.</li>
              </ul>

              <p>
              <span style={{fontWeight:"bold"}}>How you can't use SwapperLand.</span> Providing a safe and open Service for a broad community requires that we all do our part.
              </p>
              <ul>
                <li>
                   <span style={{fontWeight:"bold"}}>You can't impersonate others or provide inaccurate information.</span>
                   <p>You don't have to disclose your identity on Instagram, but you must provide us with accurate and up-to-date information (including registration information), which may include providing personal data. Also, you may not impersonate someone or something that you aren't, and you can't create an account for someone else unless you have their express permission.</p>
                </li>
                <li>
                   <span style={{fontWeight:"bold"}}>You can't do anything unlawful, misleading or fraudulent or for an illegal or unauthorised purpose.</span>
                    <p></p>
                </li>
                <li>
                   <span style={{fontWeight:"bold"}}>You can't do anything to interfere with or impair the intended operation of the Service.</span>
                   <p>This includes misusing any reporting, dispute or appeals channel, such as by making fraudulent or groundless reports or appeals.</p>
                </li>
                <li>
                   <span style={{fontWeight:"bold"}}>You can't attempt to create accounts or access or collect information in unauthorised ways.</span>
                   <p>This includes creating accounts or collecting information in an automated way without our express permission.</p>
                </li>
                <li>
                   <span style={{fontWeight:"bold"}}>You can't sell, license or purchase any account or data obtained from us or our Service.</span>
                   <p>This includes attempts to buy, sell or transfer any aspect of your account (including your username); solicit, collect or use login credentials or badges of other users; or request or collect Instagram usernames, passwords or misappropriate access tokens.</p>
                </li>
                <li>
                   <span style={{fontWeight:"bold"}}>You can't modify, translate, create derivative works of or reverse-engineer our products or their components.</span>
                    <p></p>
                </li>
              </ul>

              <p>
              <span style={{fontWeight:"bold"}}>Permissions you give to us.</span> As part of our agreement, you also give us permissions that we need to provide the Service.
              </p>
              <ul>
                <li>
                   <span style={{fontWeight:"bold"}}>We do not claim ownership of your content, but you grant us a licence to use it.</span>
                    <p>Nothing is changing about your rights in your content. We do not claim ownership of your content that you post on or through the Service and you are free to share your content with anyone else, wherever you choose. However, we need certain legal permissions from you (known as a "licence") to provide the Service. When you share, post or upload content that is covered by intellectual property rights (such as photos or videos) on or in connection with our Service, you hereby grant to us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide licence to host, use, distribute, modify, run, copy, publicly perform or display, translate and create derivative works of your content (consistent with your privacy and application settings). This licence will end when your content is deleted from our systems. You can delete content individually or all at once by deleting your account. </p>
                </li>
                <li>
                   <span style={{fontWeight:"bold"}}>Permission to use your profile picture and information about your actions with accounts.</span>
                    <p>You give us permission to show your profile picture and information about your actions, and activities in your account to perform best services to you.</p>
                </li>
                <li>
                   <span style={{fontWeight:"bold"}}>You agree that we can download and install updates to the Service on your device.</span>
                    <p></p>
                </li>
              </ul>
              <br></br>
              <h4 style={{color:"black"}}>Steps to take if disapproved</h4>
              <ul>
                <li>
                   <span style={{fontWeight:"bold"}}>Appeal the decision</span>
                    <p>If your listing has been rejected for violating our Terms of Service and you feel that it was a mistake, you can request a review by contacting our support and we'll take another look.</p>
                </li>
              </ul>

              <h4 style={{color:"black"}}>Prohibited content</h4>
              <p>Swapping of the following is prohibited on SwapperLand:</p>
              <ul>
                <li>
                   <span style={{fontWeight:"bold"}}>Body parts and fluids</span>
                    <p>Listings may not promote the swapping, buying, selling of human body parts or fluids.</p>
                    <p>-Allowed: Hair extensions and wigs</p>
                    <p>-Not Allowed: Blood, Urine, Body parts, Organs, Human tissue, Teeth, Looking for organ donors</p>
                </li>
                <li>
                   <span style={{fontWeight:"bold"}}>Stolen goods</span>
                    <p>Listings may not promote the swapping, buying, selling of items that have been stolen.</p>
                </li>
                <li>
                   <span style={{fontWeight:"bold"}}>Weapons, ammunition and explosives</span>
                    <p>Listings may not promote the swapping, buying, selling or use of weapons, ammunition and explosives.</p>
                    <p>-Allowed: Promoting safety training or licences for legal weapons</p>
                    <p>-Not Allowed: Firearms and firearm parts, Paintball guns, BB guns, Fireworks, Pepper spray, Tasers, Gun ranges, Gun shows</p>
                </li>
                <li>
                   <span style={{fontWeight:"bold"}}>Gambling</span>
                    <p>Listings may not promote the swapping, buying, selling or facilitation of online gambling for money or money's worth, including digital currencies. Online gambling includes gaming, betting, lotteries, raffles, casino, fantasy sports, bingo, poker and sweepstakes in an online environment.</p>
                </li>
                <li>
                   <span style={{fontWeight:"bold"}}>Medical and healthcare products</span>
                    <p>Listings may not promote medical and healthcare products and services, including medical devices, or smoking cessation products containing nicotine.</p>
                    <p>-Allowed: Lifestyle and fitness accessories, Ovulation and pregnancy tests</p>
                    <p>-Not Allowed: Contact lenses and reading and prescription glasses, Thermometers, Testing kits for medical conditions or diseases, First-aid kits, Breast pumps, Medical products or services</p>
                </li>
              </ul>




              <br/>
              <h4 style={{color:"black"}}>Updating these terms</h4>
              <p>
              We may change our Service and Policies, and we may need to make changes to these Terms so that they accurately reflect our Service and Policies. Unless otherwise required by law, we will notify you (for example, through our Service) at least 30 days before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your account from application.
              </p>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
}

export default Terms;