import axios from "axios";
//import AsyncStorage from "@react-native-async-storage/async-storage";


const url = "https://api.swapperland.com"; 

// const url = "http://192.168.0.206:8080";



axios.defaults.headers.post["Content-Type"] = "application/json";
//axios.defaults.headers.common['Authorization'] =`Bearer ${JSON.parse(await AsyncStorage.getItem("token"))}`;
export default {
    get: axios.get,
    post: axios.post,
    delete:axios.delete,
    url,
};
