import http from "./";


export const changePassword = async (data) => {
    try {
        const res = await http.post(
            `${http.url}/changePassword`,
            JSON.stringify(data)
        );
        return res;
    } catch (err) {
        console.log(err);
    }
};

export const registerUser = async (data) => {
    try {
        const res = await http.post(
            `${http.url}/signup`,
            JSON.stringify(data)
        );
        return res;
    } catch (err) {
        console.log(err);
    }
};
export const loginAdmin = async (data) => {
    try {
        const res = await http.post(
            `${http.url}/loginAdmin`,
            JSON.stringify(data)
        );
        return res;
    } catch (err) {
        console.log(err);
    }
};

