import React from 'react';

function Stars(props) {
    return (
        <div style={props.style}>
           {
               props.stars===5 ?
                <>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00"}}></i>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                </>
                :
                props.stars===4 ?
                <>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00"}}></i>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                </>
                :
                props.stars===3 ?
                <>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00"}}></i>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                </>
                :
                props.stars===2 ?
                <>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00"}}></i>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                </>
                :
                props.stars===1 ?
                <>
                <i className={`menu-icon tf-icons bx bxs-star bx-${props.size}`} style={{color:"#FAAF00"}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                </>
                :
                props.stars===0 ?
                <>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00"}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                <i className={`menu-icon tf-icons bx bx-star bx-${props.size}`} style={{color:"#FAAF00",marginLeft:-8}}></i>
                </>
                :null

           }
        </div>
    );
}

export default Stars;