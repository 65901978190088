import React from "react";
import "./Steps.css";
function Steps(props) {
  return (
    <section id="steps">
      <div
        style={{ textAlign: "center", width: "80%", margin: "0 auto" }}
        id="featuresSection"
      >
        <p
          style={{
            color: 'rgb(54, 50, 74)',
            fontWeight: 600,
            fontSize: 36,
          }}
        >
          How it works
        </p>
        <br></br>
        <p
          style={{
            color: 'rgb(54, 50, 74)',
            marginBottom: 25,
            fontWeight: 400,
            marginTop: -30,
            fontSize: 16,
          }}
        >
          You can follow belw order to swap in SwapperLand
        </p>
      </div>
      <div className="cardHolder">
          <div>
              <img src="img/booking.png"/>
              <div className="sep"></div>
              <h3 className="title">Register</h3>
              <p style={{textAlign:'justify'}}>
              Download SwapperLand from App Store or Google Play and register with your phone number.
              </p>
          </div>
          <div>
              <img src="img/loupe.png"/>
              <div className="sep"></div>
              <h3 className="title">Search</h3>
              <p style={{textAlign:'justify'}}>
                  Search your desired listings with many filters according to category, location and so on.
              </p>
          </div>
          <div>
              <img src="img/bidding.png"/>
              <div className="sep"></div>
              <h3 className="title">Place Bid</h3>
              <p style={{textAlign:'justify'}}>
                 After finding a listing that matches to you, place your bid on it and wait for owner to cehck your bid.
              </p>
          </div>
          <div>
              <img src="img/swap.png"/>
              <div className="sep"></div>
              <h3 className="title">Swap</h3>
              <p style={{textAlign:'justify'}}>
                  Once owner accepted your bid, now you can swap your's with his/her lisitng, and enjoy affordable life.
              </p>
          </div>
      </div>
    </section>
  );
}

export default Steps;
