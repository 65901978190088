import React, { useEffect, useRef, useState } from "react";
import Layout from "./Layout";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getSingle } from "../api/listing";
import "../core.css";
import "../theme-default.css";
import moment from "moment";
import Stars from "../components/Stars";
import "./SinlgeListing.css";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import ReactCountryFlag from "react-country-flag";
import { rejectListing, suspondListing, approveListing } from "../api/listing";
import { toast } from "react-toastify";

function SingleListing(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAFuhko7MJP9E-iW7fArpVIAPBgUL1j7U4",
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [listing, setListing] = useState();
  const [active, setActive] = useState(0);
  const [message, setMessage] = useState("");
  const selectitems = [
    // { id:0,name:"All Categories",icon:"menu"},
    { id: 1, name: "Home", icon: "home" },
    { id: 2, name: "Vehicles", icon: "md-car-sport" },
    {
      id: 3,
      name: "Woman's Clothing & Shoes",
      icon: "shoe-heel",
      type: "material",
    },
    { id: 4, name: "Mans's Clothing & Shoes", icon: "shirt" },
    { id: 5, name: "Furniture", icon: "ios-bed" },
    { id: 6, name: "Electronics", icon: "laptop-sharp" },
    { id: 7, name: "Antiques & Collectibles", icon: "md-ribbon" },
    { id: 8, name: "Appliances", icon: "washing-machine", type: "material" },
    { id: 9, name: "Arts & Crafts", icon: "color-palette-sharp" },
    { id: 10, name: "Auto Parts", icon: "steering", type: "material" },
    { id: 11, name: "Baby", icon: "baby-carriage", type: "material" },
    { id: 12, name: "Books, Movies & Music", icon: "book" },
    { id: 13, name: "Health & Beauty", icon: "heart-pulse", type: "material" },
    { id: 14, name: "Homes Goods & Decor", icon: "ios-library-sharp" },
    { id: 15, name: "Home Improvement & Tools", icon: "ios-construct" },
    { id: 16, name: "Jewelry & Watches", icon: "necklace", type: "material" },
    { id: 17, name: "Luggage & Bags", icon: "bag-personal", type: "material" },
    {
      id: 18,
      name: "Musical Instruments",
      icon: "guitar-acoustic",
      type: "material",
    },
    { id: 19, name: "Patio & Garden", icon: "shovel", type: "material" },
    { id: 20, name: "Pet & Pet Supplies", icon: "ios-paw-sharp" },
    { id: 21, name: "Sporting Goods", icon: "ios-basketball" },
    { id: 22, name: "Toys & Games", icon: "ios-game-controller" },
    { id: 23, name: "Miscellaneous", icon: "ios-albums-sharp" },
  ];
  useEffect(() => {
    if(state===null){
      document.getElementById("back2").setAttribute('style','display: none !important')
    }else{
      document.getElementById("back2").addEventListener("click", (event) => {
        navigate("/admin/listings", { state: { page: state.page } });
      });
    }
    fetchListing();
  }, []);
  const fetchListing = async () => {
    const list = await getSingle(id);
    document.title = list.title;
    setListing(list);
  };

  const nextPhoto = () => {
    if (active < listing.gallery.length - 1) {
      setActive((prev) => prev + 1);
    }
  };
  const prePhoto = () => {
    if (active >= 1) {
      setActive((prev) => prev - 1);
    }
  };
  const seperator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const rejectL = async () => {
    if (message === "") {
      toast("Please enter message to continue.", {
        theme: "light",
        type: "error",
      });
      return;
    }
    if (message.length < 30 || message.length > 300) {
      toast("Message must be between 30 and 300 charachters.", {
        theme: "light",
        type: "error",
      });
      return;
    }

    const toastId = toast.loading("Rejecting the lisiting ...");
    try {
      const res = await rejectListing({
        listingId: listing._id,
        message: message,
      });
      if (res.status === 200) {
        setListing(res.data.listing);
        setMessage("");
        window.scrollTo(0, 0);
        document.getElementById("closeModal").click();
        toast.update(toastId, {
          render: `Successfully rejected.`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.update(toastId, {
          render: "Something went wrong, Please try again",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (e) {
      toast.update(toastId, {
        render: "Something went wrong, Please try again",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  const suspondL = async () => {
    if (message === "") {
      toast("Please enter message to continue.", {
        theme: "light",
        type: "error",
      });
      return;
    }
    if (message.length < 30 || message.length > 300) {
      toast("Message must be between 30 and 300 charachters.", {
        theme: "light",
        type: "error",
      });
      return;
    }

    const toastId = toast.loading("Suspending the lisiting ...");
    try {
      const res = await suspondListing({
        listingId: listing._id,
        message: message,
      });
      if (res.status === 200) {
        setListing(res.data.listing);
        setMessage("");
        window.scrollTo(0, 0);
        document.getElementById("closeModal2").click();
        toast.update(toastId, {
          render: `Successfully suspended.`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.update(toastId, {
          render: "Something went wrong, Please try again",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (e) {
      toast.update(toastId, {
        render: "Something went wrong, Please try again",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  const approveL = async () => {
    const toastId = toast.loading("Approving the lisiting ...");
    try {
      const res = await approveListing({
        listingId: listing._id,
      });
      if (res.status === 200) {
        setListing(res.data.listing);
        setMessage("");
        window.scrollTo(0, 0);
        document.getElementById("closeModal3").click();
        toast.update(toastId, {
          render: `Successfully approved.`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.update(toastId, {
          render: "Something went wrong, Please try again",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (e) {
      toast.update(toastId, {
        render: "Something went wrong, Please try again",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  return (
    <Layout>
      {listing !== undefined ? (
        <div className="row">
          <div className="col-md">
            <div
              id="carouselExample"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <ol className="carousel-indicators">
                {listing.gallery.map((item, index) => {
                  return (
                    <li
                      data-bs-target="#carouselExample"
                      data-bs-slide-to={index}
                      className={index === active ? "active" : ""}
                    ></li>
                  );
                })}
              </ol>

              <div className="carousel-inner">
                {listing.gallery.map((item, index) => {
                  return (
                    <div
                      className={
                        active === index
                          ? "carousel-item active"
                          : "carousel-item"
                      }
                    >
                      <img
                        className="d-block w-100"
                        src={item}
                        alt="Slide"
                        style={{ borderRadius: 10, height: "70vh" }}
                      />
                    </div>
                  );
                })}
              </div>

              <a
                className="carousel-control-prev"
                onClick={prePhoto}
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                onClick={nextPhoto}
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </a>
            </div>
            {listing.status !== "Swapped" ? (
              <div className="card" style={{ marginTop: 15, marginBottom: 15 }}>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-start">
                    <h5 class="card-title">Manage Status</h5>
                    <p className="card-text" style={{ marginTop: -5 }}>
                      <small className="text-muted">
                        {" "}
                        {moment
                          .utc(listing.createdAt)
                          .local()
                          .startOf("seconds")
                          .fromNow()}
                      </small>
                    </p>
                  </div>

                  <div class="mb-3 col-12 mb-0">
                    <div class="alert alert-warning">
                      <h6 class="alert-heading fw-bold mb-1">
                        Please check all details before doing any action
                      </h6>
                      {listing.status === "Under Review" ||
                      listing.status === "Suspended" ? (
                        <p class="mb-0">
                          Once you "Reject" a listing it will be unavailabe for
                          users, By "Approve" you allow the listing to be
                          publised.
                        </p>
                      ) : listing.status === "Approved" ? (
                        <p class="mb-0">
                          Once you "Suspond" a listing, owner can't edit it and
                          it will be unavailabe for users
                        </p>
                      ) : listing.status === "Rejected" ? (
                        <p class="mb-0">
                          Once you "Suspond" a listing, owner can't edit it and
                          it will be unavailabe for users, By "Approve" you
                          allow the listing to be publised.
                        </p>
                      ) : null}
                    </div>
                  </div>
                  {listing.status === "Under Review" ? (
                    <div
                      class="demo-inline-spacing d-grid"
                      style={{
                        gridTemplateColumns: "repeat(2,1fr)",
                        marginTop: -10,
                      }}
                    >
                      <button
                        type="button"
                        class="btn btn-outline-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#modalCenter"
                      >
                        Reject
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-success"
                        data-bs-toggle="modal"
                        data-bs-target="#basicModal"
                      >
                        Approve
                      </button>
                    </div>
                  ) : listing.status === "Approved" ? (
                    <div
                      class="demo-inline-spacing d-grid"
                      style={{
                        gridTemplateColumns: "repeat(1,1fr)",
                        marginTop: -10,
                      }}
                    >
                      <button
                        type="button"
                        class="btn btn-outline-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#modalCenter2"
                      >
                        Suspond
                      </button>
                    </div>
                  ) : listing.status === "Suspended" ? (
                    <div
                      class="demo-inline-spacing d-grid"
                      style={{
                        gridTemplateColumns: "repeat(2,1fr)",
                        marginTop: -10,
                      }}
                    >
                      <button
                        type="button"
                        class="btn btn-outline-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#modalCenter"
                      >
                        Reject
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-success"
                        data-bs-toggle="modal"
                        data-bs-target="#basicModal"
                      >
                        Approve
                      </button>
                    </div>
                  ) : listing.status === "Rejected" ? (
                    <div
                      class="demo-inline-spacing d-grid"
                      style={{
                        gridTemplateColumns: "repeat(2,1fr)",
                        marginTop: -10,
                      }}
                    >
                      <button
                        type="button"
                        class="btn btn-outline-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#modalCenter2"
                      >
                        Suspond
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-success"
                        data-bs-toggle="modal"
                        data-bs-target="#basicModal"
                      >
                        Approve
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : 
            //swapped data
            <div className="card" style={{ marginTop: 15, marginBottom: 15 }}>
              <div className="card-body">

                <div className="d-flex justify-content-between align-items-start">
                    <h5 class="card-title">Details of Swapping</h5>
                    <p className="card-text" style={{ marginTop: -5 }}>
                      <small className="text-muted">
                      {" "}
                          {moment
                            .utc(listing.createdAt)
                            .local()
                            .startOf("seconds")
                            .fromNow()}
                      </small>
                    </p>
                </div>
                <br/>
                <h6 class="card-title" style={{fontWeight: "bold"}}>Swapped With</h6>
                <div class="d-flex align-items-start align-items-sm-center gap-4">
                  <img
                    src={listing.creator.image}
                    alt="user-avatar"
                    class="d-block rounded"
                    style={{ border: "1px solid #EFF2F5" }}
                    height="75"
                    width="75"
                  />
                  <div class="button-wrapper" style={{ marginLeft: -15 }}>
                    <h5>{listing.creator.name}</h5>
                    <Stars
                      stars={listing.creator.rate}
                      size="sm"
                      style={{ marginTop: -10 }}
                    />
                  </div>
                </div>
                <h6 class="card-title" style={{fontWeight: "bold",marginTop:15}}>Bid Description</h6>
                <p class="card-text" style={{marginTop: -7}}>Hdhjdjff dmvkekf French’s fnskckke fncjd jsjejc cnndnf dndn d dndn</p>
                <h6 class="card-title" style={{fontWeight: "bold",marginTop:15}}>Bid Listing(s)</h6>

                <h6 class="card-title" style={{fontWeight: "bold",marginTop:15}}>Date & Time</h6>

              </div>
            </div>
            //end swapped data
            }
          </div>

          <div className="col-md">
            <div className="card">
              <div className="card-body">
                <div style={{ textAlign: "right" }}>
                  {listing.status === "Under Review" ? (
                    <span className="badge  bg-label-warning">
                      Under Review
                    </span>
                  ) : listing.status === "Suspended" ? (
                    <span className="badge  bg-label-danger">Suspended</span>
                  ) : listing.status === "Rejected" ? (
                    <span className="badge  bg-label-danger">Rejected</span>
                  ) : listing.status === "Swapped" ? (
                    <span className="badge  bg-label-success">Swapped</span>
                  ) : listing.published === 1 ? (
                    <span className="badge  bg-label-primary">Published</span>
                  ) : (
                    <span className="badge  bg-label-info">Pending</span>
                  )}
                </div>
                <h5
                  className="card-title"
                  style={{ fontWeight: "bold", marginTop: 10 }}
                >
                  {listing.title}
                </h5>
                <br />
                <h6 className="card-title" style={{ fontWeight: "bold" }}>
                  Listed By
                </h6>
                <div class="d-flex align-items-start align-items-sm-center gap-4">
                  <img
                    src={listing.creator.image===null ? "/assets/img/profile.png" : listing.creator.image}
                    alt="user-avatar"
                    class="d-block rounded"
                    style={{ border: "1px solid #EFF2F5" }}
                    height="75"
                    width="75"
                  />
                  <div class="button-wrapper" style={{ marginLeft: -15 }}>
                    <h5>{listing.creator.name}</h5>
                    <Stars
                      stars={listing.creator.rate}
                      size="sm"
                      style={{ marginTop: -10 }}
                    />
                  </div>
                </div>
                <h6
                  className="card-title"
                  style={{ fontWeight: "bold", marginTop: 15 }}
                >
                  Description
                </h6>
                <p className="card-text" style={{ marginTop: -7 }}>
                  {listing.description}
                </p>
                <h6
                  className="card-title"
                  style={{ fontWeight: "bold", marginTop: 15 }}
                >
                  Statistics
                </h6>
                <div className="d-flex justify-content-around">
                  <div className="d-flex">
                    <i className="menu-icon bx bxs-pin bx-sm"></i>
                    <p className="card-text">
                      {seperator(listing.bids.length)} Bids
                    </p>
                  </div>
                  <div className="d-flex">
                    <i className="menu-icon bx bx-check-double bx-sm"></i>
                    <p className="card-text">
                      {seperator(listing.views.length)} Views
                    </p>
                  </div>
                </div>
                <h6
                  className="card-title"
                  style={{ fontWeight: "bold", marginTop: 15 }}
                >
                  Condition
                </h6>
                <p className="card-text" style={{ marginTop: -7 }}>
                  {listing.condition}
                </p>
                <h6
                  className="card-title"
                  style={{ fontWeight: "bold", marginTop: 15 }}
                >
                  Category
                </h6>
                <p className="card-text" style={{ marginTop: -7 }}>
                  {listing.category}
                </p>
                <h6
                  className="card-title"
                  style={{ fontWeight: "bold", marginTop: 15 }}
                >
                  Changeables
                </h6>
                <div className="changeables">
                  {listing.changeable.map((item) => {
                    return <div>{item}</div>;
                  })}
                </div>
                <h6
                  className="card-title"
                  style={{ fontWeight: "bold", marginTop: 15 }}
                >
                  Location
                </h6>
                <div style={{ display: "flex" }}>
                  <ReactCountryFlag
                    countryCode={listing.locality.country}
                    svg
                    style={{
                      fontSize: "2em",
                      height: "auto",
                      borderRadius: 5,
                    }}
                  />
                  <span
                    style={{ marginLeft: 5 }}
                    className="emp_name text-truncate"
                  >
                    {listing.locality.name}
                  </span>
                </div>

                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: 250,
                    borderRadius: 5,
                    marginTop: 10,
                  }}
                  center={{
                    lat: listing.location.coordinates[1],
                    lng: listing.location.coordinates[0],
                  }}
                  zoom={11}
                  options={{ mapTypeControl: false, streetViewControl: false }}
                >
                  <MarkerF
                    position={{
                      lat: listing.location.coordinates[1],
                      lng: listing.location.coordinates[0],
                    }}
                  />
                </GoogleMap>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            class="spinner-border text-warning"
            style={{ textAlign: "center" }}
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <div class="col-lg-4 col-md-6">
        <div class="mt-3">
          <div
            class="modal fade"
            id="modalCenter"
            tabindex="-1"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="modalCenterTitle">
                    Reject Listing
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col mb-3">
                      <div>
                        <label
                          for="exampleFormControlTextarea1"
                          class="form-label"
                        >
                          Your Message
                        </label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Enter reason here ..."
                          rows="5"
                          value={message}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    data-bs-dismiss="modal"
                    id="closeModal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={rejectL}
                  >
                    Contimue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="mt-3">
          <div
            class="modal fade"
            id="modalCenter2"
            tabindex="-1"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="modalCenterTitle">
                    Suspond Listing
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col mb-3">
                      <div>
                        <label
                          for="exampleFormControlTextarea1"
                          class="form-label"
                        >
                          Your Message
                        </label>
                        <textarea
                          class="form-control"
                          value={message}
                          id="exampleFormControlTextarea1"
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Enter reason here ..."
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    data-bs-dismiss="modal"
                    id="closeModal2"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={suspondL}
                  >
                    Contimue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="mt-3">
          <div
            class="modal fade"
            id="basicModal"
            tabindex="-1"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel1">
                    Confirmation
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <h6>Are you sure you want to approve this listing?</h6>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    data-bs-dismiss="modal"
                    id="closeModal3"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={approveL}
                  >
                    Yes, Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SingleListing;
