import React,{useEffect,useRef,useState} from "react";
import Layout from "./Layout";
import {listingsAdmin} from '../api/listing';
import ReactCountryFlag from "react-country-flag";
import Pagination from "../components/Pagination";
import { Link,useLocation } from "react-router-dom";

function Listings(props) {
  const {state} = useLocation();
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [totalItems,setTotalItems] = useState(0);
  const [totalPages,setTotalPages] = useState(1);
  const [currentPage,setCurrentPage] = useState(state!==null ? state.page : 1);
  const [render,setRender] = useState(1);
  const [limit,setLimit] = useState(10);
  const [showingTo,setShowingTo] = useState(limit);
  const [showingFrom,setShowingFrom] = useState(1);
  useEffect(() => {
      document.title = "Listings";
  }, []);

  useEffect(() => {
      fetchData();
  }, [currentPage,render]);

  const firstRef = useRef(false);
  useEffect(() => {
    if(firstRef.current===true){
      if(currentPage===1){
        setRender(render+1);
      }else{
        setCurrentPage(1);
      }
    }
    firstRef.current=true;
  }, [limit]);
  const fetchData = async ()=>{
     setLoading(true);
     const res = await listingsAdmin(currentPage,limit,'','');
    
     let showingT = res.data.posts.length===limit ? limit*currentPage : limit*currentPage - (limit - res.data.posts.length);
     setShowingTo(showingT);
     let showingF = currentPage===1 ? 1 : limit%2===0 ? (showingT-limit) + (currentPage-1) : (showingT-limit) + currentPage ;
     setShowingFrom(showingF);

     setData(res.data.posts);
     setTotalItems(res.data.totalItems);
     setTotalPages(res.data.totalPages);
     setLoading(false);
  }
  const seperator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };


  return (
    <Layout>
       <div className="row">
        <div className="card">
          <div className="card-datatable table-responsive">
            <div
              id="DataTables_Table_0_wrapper"
              className="dataTables_wrapper dt-bootstrap5 no-footer"
            >
              <div className="card-header flex-column flex-md-row">
                <div className="head-label text-center">
                  <h5 className="card-title mb-0">All Listings</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div
                    className="dataTables_length"
                    id="DataTables_Table_0_length"
                  >
                    <label>
                      Show{" "}
                      <select
                        name="DataTables_Table_0_length"
                        aria-controls="DataTables_Table_0"
                        className="form-select"
                        onChange={(e)=>{setLimit(e.target.value)}}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>{" "}
                      entries
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                  <div
                    id="DataTables_Table_0_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      Search:
                      <input
                        type="search"
                        className="form-control"
                        placeholder=""
                        aria-controls="DataTables_Table_0"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <table
                className="datatables-basic table border-top dataTable no-footer dtr-column collapsed"
                id="DataTables_Table_0"
                aria-describedby="DataTables_Table_0_info"
                style={{ width: 1109 }}
              >
                <thead>
                  <tr>
                   
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 239 }}
                      aria-label="Name: activate to sort column ascending"
                    >
                      Name
                    </th>
                  
                
                    <th
                      className="sorting dtr-hidden"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 104}}
                      aria-label="Status: activate to sort column ascending"
                    >
                      Status
                    </th>
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 150 }}
                      aria-label="Email: activate to sort column ascending"
                    >
                      Location
                    </th>
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 40 }}
                      aria-label="Email: activate to sort column ascending"
                    >
                      Bids
                    </th>
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 40 }}
                      aria-label="Email: activate to sort column ascending"
                    >
                      Views
                    </th>
                    <th
                      className="sorting_disabled dtr-hidden"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 40 }}
                      aria-label="Actions"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                 {
                   loading===false ?
                   data.map((item)=>{
                    return  <tr className="odd">
                
                    <td>
                      <div className="d-flex justify-content-start align-items-center user-name">
                        <div className="avatar-wrapper">
                          <div className="avatar me-2">
                          
                            <img src={item.gallery[0]} style={{borderRadius:5}}/>
                            
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <span className="emp_name text-truncate">
                            {item.title}
                          </span>
                          <small className="emp_post text-truncate text-muted">
                            {item.creator.name}
                          </small>
                        </div>
                      </div>
                    </td>
                
               
                    <td className="dtr-hidden">
                    {item.status === "Under Review" ? (
                    <span className="badge  bg-label-warning">
                      Under Review
                    </span>
                  ) : item.status === "Suspended" ? (
                    <span className="badge  bg-label-danger">Suspended</span>
                  ) : item.status === "Rejected" ? (
                    <span className="badge  bg-label-danger">Rejected</span>
                  ) : item.status === "Swapped" ? (
                    <span className="badge  bg-label-success">Swapped</span>
                  ): item.published === 1 ? (
                    <span className="badge  bg-label-primary">Published</span>
                  ) : (
                    <span className="badge  bg-label-info">Pending</span>
                  )}
                    </td>

                    <td>
                       <ReactCountryFlag countryCode={item.locality.country} svg 
                        style={{
                          fontSize: '2em',
                          height:"auto",
                          borderRadius:5
                        }}
                       />
                       <span style={{marginLeft:5}} className="emp_name text-truncate">{item.locality.name}</span>
                    </td>

                    <td className="dtr-hidden">
                      {seperator(item.bids.length)}
                    </td>
                    <td className="dtr-hidden">
                      {seperator(item.views.length)}
                    </td>

                    <td className="dtr-hidden">
                      <div className="d-inline-block">
                        <a
                          href="javascript:;"
                          className="btn btn-sm btn-icon dropdown-toggle hide-arrow"
                          data-bs-toggle="dropdown"
                        >
                          <i className="bx bx-dots-vertical-rounded"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end m-0">
                          <li>
                            <Link to={item._id} state={{page:currentPage}} className="dropdown-item" >
                              Details
                            </Link>
                          </li>
                          <li>
                            <Link to={item._id+"/bids"} state={{page:currentPage,listingName:item}} className="dropdown-item" >
                            Bids
                            </Link>
                          </li>
                          <div className="dropdown-divider"></div>
                          <li>
                            <a
                              href="javascript:;"
                              className="dropdown-item text-danger delete-record"
                            >
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                     
                    </td>
                  </tr>
                   })

                   :
                   <tr>
                     <td colSpan={6}>
                     <div style={{ width: "100%", textAlign: "center" }}>
                    <div
                      class="spinner-border text-warning"
                      style={{ textAlign: "center" }}
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    </div>
                     </td>
                   
                    </tr>
                 }
                </tbody>
              </table>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div
                    className="dataTables_info"
                    id="DataTables_Table_0_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing {showingFrom} to {showingTo} of {totalItems} entries
                  </div>
                </div>
                <Pagination totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Listings;
