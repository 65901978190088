import http from "./";

const token = localStorage.getItem("token");


export const usersAdmin = async (page, limit, name) => {
  try {
    const res = await http.get(
      `${
        http.url
      }/usersAdmin?page=${page}&limit=${limit}&name=${name}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const dahboardAdmin = async () => {
  try {
    const res = await http.get(
      `${http.url}/dahboardAdmin`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};


export const sendNotificationAdmin = async (data) => {
  try {
    const res = await http.post(
      `${http.url}/sendNotificationAdmin`,
      JSON.stringify(data),{
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        }
      }
   );
    return res;
  } catch (err) {
    console.log(err);
  }
};
