import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import {sendNotificationAdmin} from '../api/share';
import { toast } from "react-toastify";

function SendNotification(props) {
  useEffect(() => {
      document.title = "Send Notification";
  }, []);
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [des, setDes] = useState("");
  const sendN = async () => {
    const toastId = toast.loading("Sending Notification ...");
    try {
      const res = await sendNotificationAdmin({phone:phone,title:title,body:des});
      if (res.status === 200) {
        // setPhone("");
        // setDes("");
        // setTitle("");
        window.scrollTo(0, 0);
        toast.update(toastId, {
          render: `Successfully sent.`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.update(toastId, {
          render: "Something went wrong, Please try again",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (e) {
      toast.update(toastId, {
        render: "Something went wrong, Please try again",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  return (
    <Layout>
      <div className="row">
        <div class="col-sm-6 col-lg-6 mb-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Send Notification</h5>
              <p class="card-text">
                Fill out form below in order to send notification.
              </p>
              <div class="form-floating" style={{ marginTop: 20 }}>
                <input
                  type="text"
                  class="form-control"
                  value={phone}
                  placeholder="123456789"
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setPhone(e.target.value);
                    }
                  }}
                />
                <label for="floatingInput">Phone Number</label>
                {/* <div id="floatingInputHelp" class="form-text">
                          Make sure you enter phone number starting with + 
                        </div> */}
              </div>
              <div class="form-floating" style={{ marginTop: 10 }}>
                <input
                  type="text"
                  class="form-control"
                  value={title}
                  placeholder="Enter Title ..."
                  onChange={(e) => setTitle(e.target.value)}
                />
                <label for="floatingInput">Title</label>
              </div>
              <div class="form-floating" style={{ marginTop: 10 }}>
                <textarea
                  class="form-control"
                  style={{minHeight: 'calc(1.53em + 8.875rem + 2px)'}}
                  placeholder="Enter Description ..."
                  aria-multiline={true}
                  value={des}
                  onChange={(e) => setDes(e.target.value)}
                ></textarea>
                <label for="floatingInput">Description</label>
              </div>
              <div className="d-flex" style={{ marginTop: 10 }}>
                <button
                  type="button"
                  class="btn btn-success flex-grow-1"
                  onClick={sendN}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-6 mb-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Send SMS</h5>
              {/* <p class="card-text">
                This is another card with title and supporting text below. This
                card has some additional content to make it slightly taller
                overall.
              </p>
              <p class="card-text">
                <small class="text-muted">Last updated 3 mins ago</small>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SendNotification;
