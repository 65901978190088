import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import { Routes, Route } from "react-router-dom";
import FAQ from "./pages/FAQ";
import Rules from "./pages/Rules";
import Terms from "./pages/Terms";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Listings from "./pages/Listings";
import { store } from "./store/index";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SingleListing from "./pages/SingleListing";
import ListingBids from "./pages/ListingBids";
import SendNotification from "./pages/SendNotification";
import ListingRedirectToApp from "./pages/ListingRedirectToApp";
import socketIO from 'socket.io-client';
import http from './api/index';
const socket = socketIO.connect(http.url);
function App() {

  return (
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/loginAsAdmin" element={<Login title="Login" />} />
        <Route path="/listing/:id" element={<ListingRedirectToApp/>}/>
        <Route
          path="/admin/dashboard"
          element={<Dashboard socket={socket} />}
        />
        <Route path="/admin/users" element={<Users title="Users" />} />
        <Route path="/admin/listings" element={<Listings />} />
        <Route path="/admin/notification" element={<SendNotification />} />
        <Route path="/admin/listings/:id" element={<SingleListing />} />
        <Route path="/admin/listings/:id/bids" element={<ListingBids />} />
     
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </Provider>
  );
}

export default App;
