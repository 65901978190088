import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Nav.css";
function Nav(props) {
  const [showCross, setShowCross] = useState(0);
  const [he, setHe] = useState("0px");
  const menu = [
    {name:"Home",path:"/"},
    {name:"Features",path:"/#featuresSection"},
    // {name:"FAQ",path:"/FAQ"},
    {name:"App Screens",path:"/#screens"},
    {name:"Privacy",path:"/privacy"},
  ];
  const switchNav = () => {
    if (showCross === 0) {
      setShowCross(1);
      setHe((50 * menu.length + "px").toString());
    }
    if (showCross === 1) {
      setShowCross(0);
      setHe("0px");
    }
  };
  return (
    <>
      <div className="navbarNav">
        <div>
          <Link to="/">
            <img
              src="swapper.png"
              style={{ width: 90, height: 90, marginLeft: "-100px" }}
              className="logo"
            />
          </Link>
        </div>
        <div style={{ display: "flex" }} className="links">
          {menu.map((item) => {
            if(item.path!=="/#featuresSection" && item.path!=="/#screens" ){
              return (
                <Link to={item.path}>
                  <a href="" className="linkNav">
                    {item.name}
                  </a>
                </Link>
              );
            }else{
              return (
                  <a href={item.path} className="linkNav">
                    {item.name}
                  </a>
              );
            }
           
          })}
        </div>
        <div
          className="hideNav"
          onClick={switchNav}
          style={{
            width: 30,
            height: 30,
            display: "none",
            marginRight: 15,
            cursor: "pointer",
          }}
        >
          {he === "0px" ? (
            <svg
              className="svg1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="35"
              height="35"
            >
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
          ) : (
            <svg
              className="svg2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="35"
              height="35"
            >
              <path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM0 96C0 60.65 28.65 32 64 32H448C483.3 32 512 60.65 512 96V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V96zM48 96V416C48 424.8 55.16 432 64 432H448C456.8 432 464 424.8 464 416V96C464 87.16 456.8 80 448 80H64C55.16 80 48 87.16 48 96z" />
            </svg>
          )}
        </div>
      </div>

      <div
        className="onverlayNav"
        style={{
          height: he,
          opacity: showCross,
          
          width: "100%",
          borderBottomRightRadius: 30,
          borderBottomLeftRadius: 30,
          backgroundColor: "#EFF2F5",
          marginTop: 115,
          position: "absolute",
          zIndex: 1,
          flexDirection: "column",
          display: "flex",
          transition: "all 0.2s ease-out",
        }}
      >
        {menu.map((item) => {
          if(item.path!=="/#featuresSection" && item.path!=="/#screens" ){
            return (
              <Link to={item.path} className="link2" style={{display:showCross===1 ? "block" : "none"}}>
                <a href="" className="link2">
                  {item.name}
                </a>
              </Link>
            );
          }else{
            return (
                <a href={item.path} className="link2" style={{display:showCross===1 ? "block" : "none"}}>
                  {item.name}
                </a>
            );
          }
        })}
      </div>
    </>
  );
}

export default Nav;
