import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./SwiperCustom.css";
import { EffectCoverflow, Pagination } from "swiper";

function SwiperCustom(props) {
    return (
        <>
           <div style={{textAlign:'center',width:"100%", margin: '30px auto'}} id="screens">
        <p style={{
          color: "#36324a",
          fontWeight: 600,
         fontSize:36}}>App Screens</p>
          <br></br>
        <p style={{
          color: "#36324a",
          marginBottom: 25,
          fontWeight: 400,
          marginTop: -30,
          fontSize: 16}}>Here you can see how you will feel while working with us</p>
     
     
      </div>
         <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        loop={true}
        style={{backgroundColor:"#fff"}}
      //  autoplay={true}
      //  speed={2000}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        initialSlide={2}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
          <SwiperSlide>
            <img src='img/screens/1.png'/>
          </SwiperSlide>
          <SwiperSlide>
            <img src='img/screens/2.png'/>
          </SwiperSlide>
          <SwiperSlide>
            <img src='img/screens/3.png'/>
          </SwiperSlide>
          <SwiperSlide>
            <img src='img/screens/4.png'/>
          </SwiperSlide>
          <SwiperSlide>
            <img src='img/screens/5.png'/>
          </SwiperSlide>
          <SwiperSlide>
            <img src='img/screens/6.png'/>
          </SwiperSlide>
          <SwiperSlide>
            <img src='img/screens/7.png'/>
          </SwiperSlide>    
          <SwiperSlide>
            <img src='img/screens/8.png'/>
          </SwiperSlide>  
          <SwiperSlide>
            <img src='img/screens/9.png'/>
          </SwiperSlide>  
        </Swiper>
      </>
    );
}

export default SwiperCustom;