import http from "../api/index";

const token = localStorage.getItem("token");

export const listingsAdmin = async (page, limit, title, category) => {
  try {
    const res = await http.get(
      `${
        http.url
      }/listingsAdmin?page=${page}&limit=${limit}&title=${title}&category=${category}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};


export const getSingle = async (id) => {
  try {
    const res = await http.get(`${http.url}/getSingle/`+id, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return res.data.listing;
  } catch (err) {
    console.log(err);
  }
};

export const rejectListing = async (data) => {
  try {
    const res = await http.post(
      `${http.url}/rejectListing`,
      JSON.stringify(data),{
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        }
      }
   );
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const suspondListing = async (data) => {
  try {
    const res = await http.post(
      `${http.url}/suspondListing`,
      JSON.stringify(data),{
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        }
      }
   );
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const approveListing = async (data) => {
  try {
    const res = await http.post(
      `${http.url}/approveListing`,
      JSON.stringify(data),{
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        }
      }
   );
    return res;
  } catch (err) {
    console.log(err);
  }
};


export const bids = async (page, limit,listingId,creator,status) => {
  try {
    const res = await http.get(
      `${http.url}/bids?page=${page}&limit=${limit}&listingId=${listingId}&creator=${creator}&status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};