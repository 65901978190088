import React from 'react';
import './FAQItem.css';
function FAQItem(props) {
    return (
        <details itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
            <summary itemprop="name" style={{    display: 'block'}}>{props.q}</summary>
                <div class="details-expanded" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                <p>{props.a}</p>
            </div>
        </details>
    );
}

export default FAQItem;