import React from 'react';
import './Features.css';
function Features(props) {
    return (
        <section id="features" 
        //style={{marginTop:30}}
        >
                 <div style={{textAlign:'center',width:"80%", margin: '0 auto'}} id="featuresSection">
        <p style={{
          color: "#fff",
          fontWeight: 600,
         fontSize:36}}>App Advance Features</p>
          <br></br>
        <p style={{
          color: "#fff",
          marginBottom: 25,
          fontWeight: 400,
          marginTop: -30,
          fontSize: 16}}>Below are some unique features of SwapperLand that can meet all your needs</p>
      </div>
        <div className="features-inner">
            <div className="features-image"><img src="img/screens/3.png" width={250} /></div>
            <ul className="features-list list-1">
                <li className="features-item" data-src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/495197/phone-1.png">
                    <h3 className="features-item-header">No "fees", always free</h3>
                    <p className="features-item-text">You dont need to pay for a subscription or registration fee, any service in SwapperLand is totally free.</p>
                </li>
                <li className="features-item" data-src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/495197/phone-2.png">
                    <h3 className="features-item-header">Location base search</h3>
                    <p className="features-item-text">SwapperLand has the best search facilities for you so that you can easily search based on your desired city or region to find the stuffs you want.</p>
                </li>
                <li className="features-item" data-src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/495197/phone-3.png">
                    <h3 className="features-item-header">Everything you need</h3>
                    <p className="features-item-text">SwapperLand is worldwide base and you can find any thing you need or looking for to swap with other people in your location or any other place.</p>
                </li>
            </ul>
            <ul className="features-list list-2">
                <li className="features-item" data-src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/495197/phone-4.png">
                    <h3 className="features-item-header">User friendly interface</h3>
                    <p className="features-item-text">Anyone can easily use SwapperLand mobile applications to discover world because it is very user friendly.</p>
                </li>
                <li className="features-item" data-src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/495197/phone-5.png">
                    <h3 className="features-item-header">Bid base approach</h3>
                    <p className="features-item-text">SwapperLand allows you to submit bid to listings that you are intrested in them, also lisitings owners can decide according to bids to swap their stuffs.</p>
                </li>
                <li className="features-item" data-src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/495197/phone-6.png">
                    <h3 className="features-item-header">Manage your lisitngs</h3>
                    <p className="features-item-text">Manage all your listings in one place and easy as peace of a cake with lot of posibilites which helps you to grow up soon and feel best experince while working with us.</p>
                </li>
            </ul>
        </div>
    </section>
    );
}

export default Features;