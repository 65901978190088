import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
function Footer(props) {
  return (
    <footer className="footer">
        <div class="footerbg">
            <img src="img/footer-bg.png" alt=""/>
        </div>
        <div className="footer-col">
            <div>
               <img src="swapper.png" style={{marginTop:20}} className="lg-footer" alt="" width={70} height={70}/>
               <img src="512-1.png" style={{marginTop:20,marginBottom: '-13px'}} className="lg-footer" id="dortana" alt="" width={100} height={100}/>
            </div>
            <br></br>
            <p className="about lg-footer">You can easily swap your stuffs with others who are near you and avoid buying new stuffs, SwapperLand mission is helps you to touch an affordable life and finaly enjoy hving new stuffs with "no money"</p>
        </div>
        <div className="footer-col">
            <span className="linkNav">Extra Links</span>
            <Link to="/">
                <a href=""  className="link3">Home</a>
            </Link>
            <Link to="/faq">
              <a href=""  className="link3">FAQ</a>
            </Link>
            <Link to="/terms">
              <a href=""  className="link3">Terms of Serivce</a>
            </Link>
            <Link to="/privacy">
              <a href=""  className="link3">Privacy</a>
            </Link>
           
       
         
        </div>
        <div className="footer-col">
        <span className="linkNav">Contact Us</span>
            <a href="/aasd"  className="link3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25" height="25"><path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"/></svg>
                <span style={{marginLeft:10}}>+36 300 90 3782</span>
            </a>
            <a href="/aasd"  className="link3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25" height="25"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg> 
                <span style={{marginLeft:10}}>info@swapperland.com</span>
            </a>
            <a href="/aasd"  className="link3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="25" height="25"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"/></svg>
                <span style={{marginLeft:10}}>Budapest, Hungary</span>
            </a>
            <a href="https://instagram.com/swapperland"  className="link3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="25" height="25"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                <span style={{marginLeft:10}}>Instagram</span>
            </a>
        </div>
        <div className="footer-col">
          <div class="flex3 social-btns">
            <a class="app-btn1 blu flex vert" href="https://apps.apple.com/app/swapperland/id6444728873">
            <svg xmlns="http://www.w3.org/2000/svg" style={{    marginRight: '10px'}} viewBox="0 0 384 512" width="40" height="50"><path fill="white"  d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/></svg>
              <p className="textOnBtn">
                Available on the <br /> <span class="big-txt">App Store</span>
              </p>
            </a>

            <a class="app-btn1 blu flex vert" style={{marginTop:'15px'}} href="https://play.google.com/store/apps/details?id=com.dortana.SwapperLand">
            <svg xmlns="http://www.w3.org/2000/svg" style={{    marginRight: '10px'}} viewBox="0 0 512 512" width="40" height="40" >

<path fill="white" d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"/>

 
</svg>
            <p className="textOnBtn">
                Get it on <br /> <span class="big-txt">Google Play</span>
              </p>
            </a>
          </div>
        </div>
        <div className="seperate">
            <hr style={{ border: '1px solid white'}}/>
            <p style={{marginTop:20,marginBottom:20}}>© copyright 2022 
              <a href="https://dortana.com" style={{color:"black",textDecoration:"none",cursor:"pointer",fontWeight:"bold",zIndex:10}}> Dortana </a>
            </p>

        </div>
        
    </footer>
  );
}

export default Footer;
