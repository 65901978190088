import React, { useEffect, useRef, useState } from "react";
import Layout from "./Layout";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import { bids } from "../api/listing";
import Pagination from "../components/Pagination";
import Stars from "../components/Stars";
import "./ListingBids.css";
import moment from "moment";
import Change from "../components/Change";
function ListingBids(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(
    state !== null ? state.page : 1
  );
  const [render, setRender] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showingTo, setShowingTo] = useState(limit);
  const [showingFrom, setShowingFrom] = useState(1);

  useEffect(() => {
      document.title = "Bids";
      document.getElementById("back2").addEventListener("click", (event) => {
        navigate("/admin/listings", { state: { page: state.page } });
      });
  }, []);


  useEffect(() => {
      fetchBids();
  }, [currentPage, render]);

  const firstRef = useRef(false);
  useEffect(() => {
    if (firstRef.current === true) {
      if (currentPage === 1) {
        setRender(render + 1);
      } else {
        setCurrentPage(1);
      }
    }
    firstRef.current = true;
  }, [limit]);

  const fetchBids = async () => {
    const res = await bids(currentPage, limit, id, "", "");
   // console.log(res.data);
    let showingT =
      res.data.bids.length === limit
        ? limit * currentPage
        : limit * currentPage - (limit - res.data.bids.length);
    setShowingTo(showingT);
    let showingF =
      currentPage === 1
        ? 1
        : limit % 2 === 0
        ? showingT - limit + (currentPage - 1)
        : showingT - limit + currentPage;
    setShowingFrom(showingF);
    setData(res.data.bids);
    setTotalItems(res.data.totalItems);
    setTotalPages(res.data.totalPages);
  };
  const seperator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <Layout>
      <div classNameName="row">
        <div className="card">
          <div className="card-datatable table-responsive">
            <div
              id="DataTables_Table_0_wrapper"
              className="dataTables_wrapper dt-bootstrap5 no-footer"
            >
              <div className="card-header flex-column flex-md-row">
                <div className="head-label text-center">
                  <h5 className="card-title mb-0">
                    Bids for {state.listingName.title}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div
                    className="dataTables_length"
                    id="DataTables_Table_0_length"
                  >
                    <label>
                      Show{" "}
                      <select
                        name="DataTables_Table_0_length"
                        aria-controls="DataTables_Table_0"
                        className="form-select"
                        onChange={(e) => {
                          setLimit(e.target.value);
                        }}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>{" "}
                      entries
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                  <div
                    id="DataTables_Table_0_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      Search:
                      <input
                        type="search"
                        className="form-control"
                        placeholder=""
                        aria-controls="DataTables_Table_0"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <table
                className="datatables-basic table border-top dataTable no-footer dtr-column collapsed"
                id="DataTables_Table_0"
                aria-describedby="DataTables_Table_0_info"
                style={{ width: 1109 }}
              >
                <thead>
                  <tr>
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 239 }}
                      aria-label="Name: activate to sort column ascending"
                    >
                      Placed By
                    </th>

                    <th
                      className="sorting dtr-hidden"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 104 }}
                      aria-label="Status: activate to sort column ascending"
                    >
                      Status
                    </th>

                    <th
                      className="sorting dtr-hidden"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 104 }}
                      aria-label="Status: activate to sort column ascending"
                    >
                      Date & Time
                    </th>

                    <th
                      className="sorting_disabled dtr-hidden"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 40 }}
                      aria-label="Actions"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item,index) => {
                    return (
                      <tr className="odd">
                        <td>
                          <div className="d-flex justify-content-start align-items-center user-name">
                            <div className="avatar-wrapper">
                              <div className="avatar me-2">
                                <img
                                  src={item.creator.image===null ? "/assets/img/profile.png" : item.creator.image}

                                  style={{ borderRadius: 5 }}
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <span className="emp_name text-truncate">
                                {item.creator.name}
                              </span>
                              <Stars
                                stars={item.creator.rate}
                                size="sm"
                                style={{ marginTop: -5 }}
                              />
                            </div>
                          </div>
                        </td>

                        <td className="dtr-hidden">
                          {item.status === "Pending" ? (
                            <span className="badge  bg-label-warning">
                              Pending
                            </span>
                          ) : item.status === "Approved" ? (
                            <span className="badge  bg-label-success">
                              Approved
                            </span>
                          ) : item.status === "Declined" ? (
                            <span className="badge bg-label-danger">
                              Declined
                            </span>
                          ) : null}
                        </td>

                        <td className="dtr-hidden">
                          <div className="d-flex flex-column">
                            <span className="emp_name text-truncate">
                              {moment
                                .utc(item.createdAt)
                                .format("MMMM Do YYYY, h:mm:ss a")}
                            </span>
                            <small class="emp_post text-truncate text-muted">
                              {moment
                                .utc(item.createdAt)
                                .local()
                                .startOf("seconds")
                                .fromNow()}
                            </small>
                          </div>
                        </td>

                        <td className="dtr-hidden">
                          <div className="d-inline-block">
                            <a
                              href="javascript:;"
                              className="btn btn-sm btn-icon dropdown-toggle hide-arrow"
                              data-bs-toggle="dropdown"
                            >
                              <i className="bx bx-dots-vertical-rounded"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end m-0">
                              <li>
                                <a
                                  data-bs-toggle="modal"
                                  className="dropdown-item"
                                  data-bs-target={`#basicModal${index}`}
                                  style={{ cursor: "pointer" }}
                                >
                                  Details
                                </a>
                               
                              </li>
                              <div className="dropdown-divider"></div>
                              <li>
                                <a
                                  href="javascript:;"
                                  className="dropdown-item text-danger delete-record"
                                >
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div
                                  class="modal fade"
                                  id={`basicModal${index}`}
                                  tabindex="-1"
                                  aria-hidden="true"
                                >
                                  <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5
                                          class="modal-title"
                                          id="exampleModalLabel1"
                                        >

                                        Current Status: 
                                        </h5>
                                          <div style={{marginLeft:5}}>
                                          {item.status === "Pending" ? (
                                            <span className="badge  bg-label-warning">
                                              Pending
                                            </span>
                                          ) : item.status === "Approved" ? (
                                            <span className="badge  bg-label-success">
                                              Approved
                                            </span>
                                          ) : item.status === "Declined" ? (
                                            <span className="badge bg-label-danger">
                                              Declined
                                            </span>
                                          ) : null}
                                          </div>
                                        <button
                                          type="button"
                                          class="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                      <div class="modal-body">
                                        <div class="row">
                                          <div class="col mb-3">
                                              <h6 class="card-title" style={{fontWeight: "bold",marginTop:15}}>Placed By</h6>
                                              <div className="d-flex justify-content-start align-items-center user-name" style={{marginTop:-5}}>
                                                <div className="avatar-wrapper">
                                                  <div className="avatar me-2">
                                                    <img
                                                      src={item.creator.image===null ? "/assets/img/profile.png" : item.creator.image}

                                                      style={{ borderRadius: 5 }}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="d-flex flex-column">
                                                  <span className="emp_name text-truncate">
                                                    {item.creator.name}
                                                  </span>
                                                  <Stars
                                                    stars={item.creator.rate}
                                                    size="sm"
                                                    style={{ marginTop: -5 }}
                                                  />
                                                </div>
                                              </div>
                                              <h6 class="card-title" style={{fontWeight: "bold",marginTop:15}}>Bid Description</h6>
                                              <p class="card-text" style={{marginTop: -7}}>Hdhjdjff dmvkekf French’s fnskckke fncjd jsjejc cnndnf dndn d dndn</p>
                                              <h6 class="card-title" style={{fontWeight: "bold",marginTop:15}}>Bid Listing(s)</h6>
                                              
                                              <div className="lists">
                                               {
                                                 item.changeable.map((item)=>{
                                                    return  <Change item={item}/>
                                                 })
                                               }
                                              </div>

                                              <div className="d-flex flex-column" style={{marginTop:30}}>
                                                <span className="card-text">
                                                  {moment
                                                    .utc(item.createdAt)
                                                    .format("MMMM Do YYYY, h:mm:ss a")}
                                                </span>
                                                <small class="emp_post text-truncate text-muted">
                                                  {moment
                                                    .utc(item.createdAt)
                                                    .local()
                                                    .startOf("seconds")
                                                    .fromNow()}
                                                </small>
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="modal-footer" style={{marginTop: -10}}>
                                        <button
                                          type="button"
                                          class="btn btn-outline-secondary"
                                          data-bs-dismiss="modal"
                                        >
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                        </td>
              
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div
                    className="dataTables_info"
                    id="DataTables_Table_0_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing {showingFrom} to {showingTo} of {totalItems} entries
                  </div>
                </div>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  changePage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ListingBids;
