import React, { useEffect, useRef, useState } from "react";
import Layout from "./Layout";
import { useLocation } from "react-router-dom";
import Pagination from "../components/Pagination";
import Stars from "../components/Stars";
import { usersAdmin } from "../api/share";
import moment from "moment";

function Users(props) {
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(
    state !== null ? state.page : 1
  );
  const [render, setRender] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showingTo, setShowingTo] = useState(limit);
  const [showingFrom, setShowingFrom] = useState(1);

  useEffect(() => {
      document.title = props.title;
  }, []);

  useEffect(() => {
      fetchUsers();
  }, [currentPage, render]);
  const firstRef = useRef(false);
  useEffect(() => {
    if (firstRef.current === true) {
      if (currentPage === 1) {
        setRender(render + 1);
      } else {
        setCurrentPage(1);
      }
    }
    firstRef.current = true;
  }, [limit]);

  const fetchUsers = async () => {
    setLoading(true);
    const res = await usersAdmin(currentPage, limit,'');
    let showingT =
      res.data.users.length === limit
        ? limit * currentPage
        : limit * currentPage - (limit - res.data.users.length);
    setShowingTo(showingT);
    let showingF =
      currentPage === 1
        ? 1
        : limit % 2 === 0
        ? showingT - limit + (currentPage - 1)
        : showingT - limit + currentPage;
    setShowingFrom(showingF);
    setData(res.data.users);
    setTotalItems(res.data.totalItems);
    setTotalPages(res.data.totalPages);
    setLoading(false);
  };
  const seperator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <Layout>
      <div classNameName="row">
        <div className="card">
          <div className="card-datatable table-responsive">
            <div
              id="DataTables_Table_0_wrapper"
              className="dataTables_wrapper dt-bootstrap5 no-footer"
            >
              <div className="card-header flex-column flex-md-row">
                <div className="head-label text-center">
                  <h5 className="card-title mb-0">All Users</h5>
                </div>
                <div className="dt-action-buttons text-end pt-3 pt-md-0">
                  <div className="dt-buttons">
                    <button
                      className="dt-button create-new btn btn-primary"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      type="button"
                    >
                      <span>
                        <i className="bx bx-plus me-sm-2"></i>{" "}
                        <span className="d-none d-sm-inline-block">
                          Add New User
                        </span>
                      </span>
                    </button>{" "}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div
                    className="dataTables_length"
                    id="DataTables_Table_0_length"
                  >
                    <label>
                      Show{" "}
                      <select
                        name="DataTables_Table_0_length"
                        aria-controls="DataTables_Table_0"
                        className="form-select"
                      >
                        <option value="7">7</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>{" "}
                      entries
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                  <div
                    id="DataTables_Table_0_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      Search:
                      <input
                        type="search"
                        className="form-control"
                        placeholder=""
                        aria-controls="DataTables_Table_0"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <table
                className="datatables-basic table border-top dataTable no-footer dtr-column collapsed"
                id="DataTables_Table_0"
                aria-describedby="DataTables_Table_0_info"
                style={{ width: 1109 }}
              >
                <thead>
                  <tr>
                    <th
                      className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 18 }}
                      data-col="1"
                      aria-label=""
                    >
                      <input type="checkbox" className="form-check-input" />
                    </th>
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 239 }}
                      aria-label="Name: activate to sort column ascending"
                    >
                      Name
                    </th>
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 150 }}
                      aria-label="Email: activate to sort column ascending"
                    >
                      Phone Number
                    </th>
                
                   
                    <th
                      className="sorting dtr-hidden"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 104}}
                      aria-label="Status: activate to sort column ascending"
                    >
                      Status
                    </th>
                    <th
                      className="sorting dtr-hidden"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 104}}
                      aria-label="Status: activate to sort column ascending"
                    >
                      Listings
                    </th>
                    <th
                      className="sorting dtr-hidden"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 104}}
                      aria-label="Status: activate to sort column ascending"
                    >
                      Registration
                    </th>
                    <th
                      className="sorting_disabled dtr-hidden"
                      rowspan="1"
                      colspan="1"
                      style={{ width: 62 }}
                      aria-label="Actions"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    loading===false?
                    data.map((item)=>{
                      return     <tr className="odd">
                      <td className="  dt-checkboxes-cell">
                        <input
                          type="checkbox"
                          className="dt-checkboxes form-check-input"
                        />
                      </td>
                      <td>
                        <div className="d-flex justify-content-start align-items-center user-name">
                          <div className="avatar-wrapper">
                            <div className="avatar me-2">
                            <img
                                  src={item.image===null ? "/assets/img/profile.png" : item.image}
                                  style={{ borderRadius: 5 }}
                                />
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <span className="emp_name text-truncate">
                              {item.name}
                            </span>
                            <small className="emp_post text-truncate text-muted">
                            <Stars
                                stars={item.rate}
                                size="sm"
                                style={{ marginTop: -5 }}
                              />
                            </small>
                          </div>
                        </div>
                      </td>
                      <td>{item.phone}</td>
                 
   
                      <td className="dtr-hidden">
                        <span className="badge  bg-label-success">
                          {item.status}
                        </span>
                      </td>
                      <td className="dtr-hidden">{seperator(item.listings.length)}</td>
                      <td className="dtr-hidden">{moment
                                .utc(item.createdAt)
                                .format("MMMM Do YYYY, HH:MM:SS")}</td>
                      <td className="dtr-hidden">
                        <div className="d-inline-block">
                          <a
                            href="javascript:;"
                            className="btn btn-sm btn-icon dropdown-toggle hide-arrow"
                            data-bs-toggle="dropdown"
                          >
                            <i className="bx bx-dots-vertical-rounded"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end m-0">
                            <li>
                              <a href="javascript:;" className="dropdown-item">
                                Details
                              </a>
                            </li>
                            <li>
                              <a href="javascript:;" className="dropdown-item">
                                Archive
                              </a>
                            </li>
                            <div className="dropdown-divider"></div>
                            <li>
                              <a
                                href="javascript:;"
                                className="dropdown-item text-danger delete-record"
                              >
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                       
                      </td>
                    </tr>
                    })
                    :
                    <tr>
                    <td colSpan={6}>
                    <div style={{ width: "100%", textAlign: "center" }}>
                   <div
                     class="spinner-border text-warning"
                     style={{ textAlign: "center" }}
                     role="status"
                   >
                     <span class="visually-hidden">Loading...</span>
                   </div>
                   </div>
                    </td>
                  
                   </tr>
                  }

              


                </tbody>
              </table>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div
                    className="dataTables_info"
                    id="DataTables_Table_0_info"
                    role="status"
                    aria-live="polite"
                  >
                   Showing {showingFrom} to {showingTo} of {totalItems} entries
                  </div>
                </div>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  changePage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offcanvas offcanvas-end" id="add-new-record">
        <div className="offcanvas-header border-bottom">
          <h5 className="offcanvas-title" id="exampleModalLabel">
            New Record
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow-1">
          <form
            className="add-new-record pt-0 row g-2"
            id="form-add-new-record"
            onsubmit="return false"
          >
            <div className="col-sm-12">
              <label className="form-label" for="basicFullname">
                Full Name
              </label>
              <div className="input-group input-group-merge">
                <span id="basicFullname2" className="input-group-text">
                  <i className="bx bx-user"></i>
                </span>
                <input
                  type="text"
                  id="basicFullname"
                  className="form-control dt-full-name"
                  name="basicFullname"
                  placeholder="John Doe"
                  aria-label="John Doe"
                  aria-describedby="basicFullname2"
                />
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label" for="basicPost">
                Post
              </label>
              <div className="input-group input-group-merge">
                <span id="basicPost2" className="input-group-text">
                  <i class="bx bxs-briefcase"></i>
                </span>
                <input
                  type="text"
                  id="basicPost"
                  name="basicPost"
                  className="form-control dt-post"
                  placeholder="Web Developer"
                  aria-label="Web Developer"
                  aria-describedby="basicPost2"
                />
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label" for="basicEmail">
                Email
              </label>
              <div className="input-group input-group-merge">
                <span className="input-group-text">
                  <i className="bx bx-envelope"></i>
                </span>
                <input
                  type="text"
                  id="basicEmail"
                  name="basicEmail"
                  className="form-control dt-email"
                  placeholder="john.doe@example.com"
                  aria-label="john.doe@example.com"
                />
              </div>
              <div className="form-text">
                You can use letters, numbers & periods
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label" for="basicDate">
                Joining Date
              </label>
              <div className="input-group input-group-merge">
                <span id="basicDate2" className="input-group-text">
                  <i class="bx bx-calendar"></i>
                </span>
                <input
                  type="text"
                  className="form-control dt-date"
                  id="basicDate"
                  name="basicDate"
                  aria-describedby="basicDate2"
                  placeholder="MM/DD/YYYY"
                  aria-label="MM/DD/YYYY"
                />
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label" for="basicSalary">
                Salary
              </label>
              <div className="input-group input-group-merge">
                <span id="basicSalary2" className="input-group-text">
                  <i class="bx bx-dollar"></i>
                </span>
                <input
                  type="number"
                  id="basicSalary"
                  name="basicSalary"
                  className="form-control dt-salary"
                  placeholder="12000"
                  aria-label="12000"
                  aria-describedby="basicSalary2"
                />
              </div>
            </div>
            <div className="col-sm-12">
              <button
                type="submit"
                className="btn btn-primary data-submit me-sm-3 me-1"
              >
                Submit
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary"
                data-bs-dismiss="offcanvas"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default Users;
