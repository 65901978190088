import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../core.css";
import "../theme-default.css";
import { loginAdmin } from "../api/auth";
import { decodeToken } from "../api/token";
import { toast } from 'react-toastify';
import {useDispatch} from 'react-redux'
import {userAction} from '../actions/index'
function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if(token){
      navigate('/admin/dashboard');
    }else{
      document.title = props.title;
    }
  }, []);
  const mobile = useRef("");
  const password = useRef("");
  const doLogin = async () => {
    if(mobile.current==="" || password.current===""){
      toast("Please enter data to continue.",{theme:"light",type:"error"});
      return;
    }
    const toastId = toast.loading("Loggin in ...");
    try{
        const res = await loginAdmin({
          phone: mobile.current,
          password: password.current,
        });
        if(res.status===201){
          localStorage.setItem("token", JSON.stringify(res.data.token));
          const decodedToken = decodeToken(res.data.token);
          dispatch(userAction(decodedToken.user));
          toast.update(toastId, { render: `Welcome Back ${decodedToken.user.fullname} 👋`, type: "success", isLoading: false,autoClose: 3000  });
          navigate('/admin/dashboard');
        }else if(res.status===200){
          toast.update(toastId, { render: "Invalid phone or password.", type: "error", isLoading: false,autoClose: 3000  });
        }else{
        toast.update(toastId, { render: "Something went wrong, Please try again", type: "error", isLoading: false,autoClose: 3000 });
        }
    }catch(e){
      toast.update(toastId, { render: "Something went wrong, Please try again", type: "error", isLoading: false,autoClose: 3000  });
    }
  };
  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center">
                <a href="/" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <img src="swapper.png" width={45} height={45} />
                  </span>
                </a>
              </div>
              <h4 className="mb-2">Welcome to SwapperLand! 👋</h4>
              <p className="mb-4">Please sign-in to your account</p>
              <div id="formAuthentication" className="mb-3">
                <div className="mb-3">
                  <label for="mobile" className="form-label">
                    Mobile
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mobile"
                    name="mobile"
                    placeholder="Enter your mobile number"
                    autofocus
                    onChange={(e) => (mobile.current = e.target.value)}
                  />
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" for="password">
                      Password
                    </label>
                  </div>
                  <div className="input-group">
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      name="password"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                      onChange={(e) => (password.current = e.target.value)}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <button
                    className="btn btn-primary d-grid w-100"
                    type="button"
                    onClick={doLogin}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
