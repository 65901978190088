import React from "react";

function Pagination(props) {
  const { totalPages, currentPage, changePage } = props;
  const totalPagesArray = [];
  for (let i = 0; i < totalPages; i++) {
    totalPagesArray.push({ item: i });
  }
  return (
    <div className="col-sm-12 col-md-6">
      <div
        className="dataTables_paginate paging_simple_numbers"
        id="DataTables_Table_0_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item previous ${
              currentPage === 1 ? "disabled" : ""
            }`}
            id="DataTables_Table_0_previous"
            style={
              currentPage !== 1 ? { cursor: "pointer" } : { cursor: "default" }
            }
          >
            <a
              onClick={() =>
                currentPage === 1 ? null : changePage(currentPage - 1)
              }
              aria-controls="DataTables_Table_0"
              data-dt-idx="0"
              tabindex="0"
              className="page-link"
            >
              Previous
            </a>
          </li>

          {totalPagesArray.map((item, index) => {
            return (
              <li
                style={{ cursor: "pointer" }}
                className={
                  index + 1 !== currentPage
                    ? "paginate_button page-item"
                    : "paginate_button page-item active"
                }
              >
                <a
                  onClick={() => changePage(index + 1)}
                  aria-controls="DataTables_Table_0"
                  data-dt-idx="1"
                  tabindex="0"
                  className="page-link"
                >
                  {index + 1}
                </a>
              </li>
            );
          })}

          <li
            className="paginate_button page-item next"
            id="DataTables_Table_0_next"
            style={
              currentPage !== totalPages
                ? { cursor: "pointer" }
                : { cursor: "default" }
            }
          >
            <a
              onClick={() =>
                currentPage === totalPages ? null : changePage(currentPage + 1)
              }
              aria-controls="DataTables_Table_0"
              data-dt-idx="8"
              tabindex="0"
              className="page-link"
            >
              Next
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Pagination;
