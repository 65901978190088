import React from 'react';
import Footer from "../components/Footer";
import Nav from "../components/Nav";

function Rules(props) {
    React.useEffect(() => {
      window.scrollTo(0, 0)
    }, []);
    return (
        <>
        <div
          style={{
            background:
              "linear-gradient(rgba(30, 149, 155, 0.7), rgba(243, 168, 35, 0.7)), url(https://source.unsplash.com/random)",
            width: "100%",
            minHeight: "50vh",
          }}
        >
          <Nav />
          <div
            style={{
              width: "100%",
              textAlign: "center",
              height: "100%",
              position: "absolute",
              top: "30vh",
              fontSize: "2.5em",
              color: "#36324a",
              fontWeight: "bold",
            }}
          >
            Rules
          </div>
        </div>
        <div style={{paddingTop: 16,
      borderTop: '1px solid #eee'}}>
          <div style={{    position: 'relative',
      width: '90%',
      maxWidth: '1024px',
      margin: '0 auto'}}>
            <div id="en">
              SwapperLand Rules
            </div>
          </div>
        </div>
        <Footer/>
      </>
    );
}

export default Rules;