import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function ListingRedirectToApp(props) {
  const { id } = useParams();
  useEffect(async() => {
    document.getElementById("swapperland").click();
  }, []);
  return (
      <a href={"swapperland://listing?item=" + id} id="swapperland" />
  );
}

export default ListingRedirectToApp;
