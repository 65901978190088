import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { dahboardAdmin } from "../api/share";
import Clock from "react-live-clock";
import {useSelector} from 'react-redux'

function Dashboard({socket}) {
  const user = useSelector(state=>state.user);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState(0);
  useEffect(() => {
      document.title = "Dashboard";
      fetchData();
  }, []);
  const fetchData = async () => {
    const res = await dahboardAdmin();
    setData(res.data);
    setUsers(res.data.onlines);
  };
  const seperator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  useEffect(()=>{
    if(user.userId){
     socket.emit("join",user.userId);
    }
  },[user]);

  useEffect(()=>{
      socket.on("onlineUsers",(number)=>{
        setUsers(number);
      });
  },[socket]);

  return (
    <Layout>
      {data !== null ? (
        <div class="row">
          <div class="col-lg-8 mb-4 order-0">
            <div class="card">
              <div class="d-flex align-items-end row">
                <div class="col-sm-7">
                  <div class="card-body">
                    <h5 class="card-title text-base">Welcome Admin! 🎉</h5>
                    <p class="mb-4">
                      You have <span class="fw-bold">{data.waiting}</span>{" "}
                      listings to review today. Go to listings and review all
                      them.
                    </p>

                    <Clock
                      style={{ color: "#000" }}
                      format={"dddd, MMMM Mo, YYYY, HH:mm:ss"}
                      ticking={true}
                      timezone={"Europe/Budapest"}
                    />
                  </div>
                </div>
                <div class="col-sm-5 text-center text-sm-left">
                  <div class="card-body pb-0 px-0 px-md-4">
                    <img
                      src="../assets/img/illustrations/man-with-laptop-light.png"
                      height="140"
                      alt="View Badge User"
                      data-app-dark-img="illustrations/man-with-laptop-dark.png"
                      data-app-light-img="illustrations/man-with-laptop-light.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 order-1">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-6 mb-4">
                <div class="card" style={{ height: 180 }}>
                  <div class="card-body">
                    <div class="card-title d-flex align-items-start justify-content-between">
                      <div
                        class="avatar flex-shrink-0 me-3"
                        style={{
                          margin: "0 auto",
                          marginRight: "auto",
                          width: "100%",
                          height: 30,
                        }}
                      >
                        <span class="avatar-initial rounded bg-label-base">
                          <i class="bx bx-user"></i>
                        </span>
                      </div>
                    </div>
                    <span class="fw-semibold d-block mb-1">Total Users</span>
                    <h3 class="card-title text-nowrap">
                      {seperator(data.allUsers)}
                    </h3>
                    <small class="text-success fw-semibold">
                      <i class="bx bx-up-arrow-alt"></i> +72.80%
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-6 mb-4">
                <div class="card" style={{ height: 180 }}>
                  <div class="card-body">
                    <div class="card-title d-flex align-items-start justify-content-between">
                      <div
                        class="avatar flex-shrink-0 me-3"
                        style={{
                          margin: "0 auto",
                          marginRight: "auto",
                          width: "100%",
                          height: 30,
                        }}
                      >
                        <span class="avatar-initial rounded bg-label-base">
                          <i class="bx bx-purchase-tag"></i>
                        </span>
                      </div>
                    </div>
                    <span class="fw-semibold d-block mb-1">Listings</span>
                    <h3 class="card-title text-nowrap">
                      {seperator(data.allListings)}
                    </h3>
                    <small class="text-success fw-semibold">
                      <i class="bx bx-up-arrow-alt"></i> +28.42%
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-4 order-3 order-md-2">
          <div class="row">
              <div class="col-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <div
                      class="d-flex justify-content-between flex-sm-row flex-column gap-3"
                      style={{position:"relative"}}
                    >
                      <div class="d-flex flex-sm-column flex-row align-items-start justify-content-between">
                        <div class="card-title">
                          <h5 class="text-nowrap mb-2">Online Users</h5>
                         
                        </div>
                        <div class="mt-sm-auto d-flex">
                         <span class="badge bg-label-base">
                            <i class="bx bx-user"></i>
                         
                          </span>
                          <h3 style={{marginLeft:5}} class="mb-0">{seperator(users)}</h3>
                        </div>
                      </div>
                        

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            class="spinner-border text-warning"
            style={{ textAlign: "center" }}
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Dashboard;
