import React, { useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import "../core.css";
import "../theme-default.css";
import {useSelector,useDispatch} from 'react-redux'
import {userAction} from '../actions/index'
import { decodeToken } from "../api/token";
import { toast } from 'react-toastify';


function Layout(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state=>state.user);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if(!token){
      navigate('/loginAsAdmin');
    }else{
      const decodedToken = decodeToken(token);
      dispatch(userAction(decodedToken.user));
      document.title = props.title;
    }
  }, []);
  const url = window.location.href;
  const logout = ()=>{
    localStorage.removeItem("token");
    toast.success("Looged out successfully.");
    navigate('/loginAsAdmin');
  }

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <aside
          id="layout-menu"
          className="layout-menu menu-vertical menu bg-menu-theme"
        >
          <div className="app-brand demo">
            <Link to="/" className="app-brand-link">
              <span className="app-brand-logo demo">
                <img src="/swapper.png" width={45} height={45} />
              </span>
              <span
                className="app-brand-text demo menu-text fw-bolder ms-2"
                style={{ fontSize: "1.2rem", textTransform: "none" }}
              >
                SwapperLand
              </span>
            </Link>

            <a
              href=""
              className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
              onClick={(e) => {
                event.preventDefault();
                window.Helpers.toggleCollapsed();
              }}
            >
              <i className="bx bx-chevron-left bx-sm align-middle"></i>
            </a>
          </div>

          <div className="menu-inner-shadow"></div>

          <ul className="menu-inner py-1" style={{ overflowY: "scroll" }}>
            <li
              className={
                url.includes("dashboard") ? "menu-item active" : "menu-item"
              }
            >
              <Link to="/admin/dashboard" className="menu-link">
                <i className="menu-icon tf-icons bx bx-home-circle"></i>
                <div data-i18n="Analytics">Dashboard</div>
              </Link>
            </li>
            <li
              className={
                url.includes("users") ? "menu-item active" : "menu-item"
              }
            >
              <Link to="/admin/users" className="menu-link">
                <i className="menu-icon tf-icons bx bx-user"></i>
                <div data-i18n="Layouts">Users</div>
              </Link>
            </li>
            <li
              className={
                url.includes("listings") ? "menu-item active" : "menu-item"
              }
            >
              <Link to="/admin/listings" className="menu-link">
                <i className="menu-icon tf-icons bx bx-purchase-tag"></i>
                <div data-i18n="Layouts">Listings</div>
              </Link>
            </li>

            <li
              className={
                url.includes("notification") ? "menu-item active" : "menu-item"
              }
            >
              <Link to="/admin/notification" className="menu-link">
                <i className="menu-icon tf-icons bx bx-bell"></i>
                <div data-i18n="Layouts">Send Notification</div>
              </Link>
            </li>
            
          </ul>
        </aside>

        <div className="layout-page">
          <nav
            className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
            id="layout-navbar"
          >
            <div
              className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none"
              onClick={(e) => {
                event.preventDefault();
                window.Helpers.toggleCollapsed();
              }}
            >
              <a
                className="nav-item nav-link px-0 me-xl-4"
                href="javascript:void(0)"
              >
                <i className="bx bx-menu bx-sm"></i>
              </a>
            </div>

            <div
              className="navbar-nav-right d-flex align-items-center"
              id="navbar-collapse"
            >
               {
                 window.location.href.includes('listings/') ?
                 <div className="navbar-nav align-items-center d-flex flex-row" id="back2" style={{cursor:"pointer"}} 
                 >
                  <i className="menu-icon tf-icons bx bx-chevron-left" style={{fontSize: 22}}></i>
                  <span style={{marginLeft:-10}}>Back</span>
                </div>
                :null
               }
               


              <ul className="navbar-nav flex-row align-items-center ms-auto">
                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                  <a
                    className="nav-link dropdown-toggle hide-arrow"
                    href="javascript:void(0);"
                    data-bs-toggle="dropdown"
                  >
                    <div className="avatar avatar-online">
                      <img
                         src={user.image ? user.image : "../assets/img/avatars/1.png"}
                        alt
                        className="w-px-40 h-auto rounded-circle"
                      />
                    </div>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar avatar-online">
                              <img
                                src={user.image ? user.image : "../assets/img/avatars/1.png"}
                                alt
                                className="w-px-40 h-auto rounded-circle"
                              />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <span className="fw-semibold d-block">
                              {user.fullname}
                            </span>
                            <small className="text-muted">{user.role}</small>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <i className="bx bx-user me-2"></i>
                        <span className="align-middle">My Profile</span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <i className="bx bx-cog me-2"></i>
                        <span className="align-middle">Settings</span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <span className="d-flex align-items-center align-middle">
                          <i className="flex-shrink-0 bx bx-credit-card me-2"></i>
                          <span className="flex-grow-1 align-middle">
                            Billing
                          </span>
                          <span className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">
                            4
                          </span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                  
                        <a className="dropdown-item" style={{cursor:"pointer"}} onClick={logout}>
                          <i className="bx bx-power-off me-2"></i>
                          <span className="align-middle">Log Out</span>
                        </a>
               
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              {props.children}
            </div>
          </div>
        </div>
      </div>
     
      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  );
}

export default Layout;
