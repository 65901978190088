import React from 'react';
import FAQItem from '../components/FAQItem';
import Footer from "../components/Footer";
import Nav from "../components/Nav";

function FAQ(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    return (
        <div style={{backgroundColor:"white"}}>
      <div
        style={{
          background:
            "linear-gradient(rgba(30, 149, 155, 0.7), rgba(243, 168, 35, 0.7)), url(https://source.unsplash.com/random)",
          width: "100%",
          minHeight: "50vh",
          marginBottom:50,
        }}
      >
        <Nav />
        <div
          style={{
            width: "100%",
            textAlign: "center",
            height: "100%",
            position: "absolute",
            top: "30vh",
            fontSize: "2.5em",
            color: "#36324a",
            fontWeight: "bold",
          }}
        >
          FAQ
        </div>
      </div>
      <div style={{marginLeft: '5%',fontSize: 25,fontWeight:"bold", color: 'rgb(54, 50, 74)'}}>Frequently Asked Questions</div>
      <div style={{marginLeft: '5%',fontSize: 20,marginTop:10,marginBottom:20, color: 'rgb(54, 50, 74)'}}>Answers to the most frequently asked questions</div>
      <FAQItem q="How many listings I can add?" a="You can have unlimited listings."/>
      <FAQItem q="Is SwapperLand free?" a="Yes, SwapperLand application is free and anyone can access it all over the world."/>
      <FAQItem q="Is it possible to hide my listing from other people sometimes?" a="Yes, after your listing has been approved to publish by defualt we will publish it. you can switch status of your listing to unpublished for certain period of time also you can pusblish it again."/>
      <FAQItem q="If I edit my listing, it goes for review again?" a="Yes, your listing goes for a quick standard review to make sure it follows our Terms of Service before they are visible to other people."/>
      <FAQItem q="What happens if owner of a listing rejects our bid?" a="If owner has not block you, you can submit new bid to owner with more attractive offer."/>
      <FAQItem q="Can I have two active bid at the same time?" a="You only can have active bid for one listing, however you can place bid for other listings at the meantime."/>
      <FAQItem q="What happens after my bid was accepted by owner?" a="You will receive owners phone number to contact with him/her and swap your stuffs together. keep in mind that when owner accepts your bid, still system allows other people to place new bids untill owner marks that lisiting as swapped. so you should be hurry."/>
      <FAQItem q="When I add a lisitng how long does it take to be approved and published in SwapperLand?" a="Usually about 5 minutes upto 1 hour."/>
      <FAQItem q="Can I have two accounts in SwapperLand?" a="No, unfortunately you only can have one active account. please read our Terms of Serivce."/>
      <FAQItem q="Can I register in SwapperLand by email?" a="Only with phone number you can register in SwapperLand."/>
      <FAQItem q="Is it possible to search other cities in SwapperLand?" a="Yes, you can change your location in SwapperLand application to see listings in that area."/>
      <FAQItem q="What I have to do when my listing is suspended?" a="You need to contact with our support team."/>
      <FAQItem q="What kind of listings are forbidden in SwapperLand?" a="You can't add listings related to drugs, drug paraphernalia, items that have been stolen, body parts and fluids and etc. Please read our Terms of Serivce for compelete details."/>
      <FAQItem q="If my listing was rejected by SwapperLand what I have to do?" a="If your listing has been rejected for violating our Terms of Serivce and you feel that it was a mistake, you can contact our support and we'll take another look."/>
      <FAQItem q="When we can submit rate to other people?" a="When you swap your listing with another person, the owner will submit a rating about you."/>
      <Footer/>
    </div>
    );
}

export default FAQ;